import styled, { css, StyledComponent } from 'styled-components';

import { ReactComponent as IconMapSvg } from '../../../assets/icons/icon-map.svg';
import { ReactComponent as IconEBikeSvg } from '../../../assets/icons/icon-e-bike.svg';
import { ReactComponent as IconEBikeWhiteSvg } from '../../../assets/icons/icon-e-bike-white.svg';
import { ReactComponent as IconFlagSvg } from '../../../assets/icons/flag.svg';
import { ReactComponent as IconHomeSvg } from '../../../assets/icons/home.svg';
import { ReactComponent as IconHomeRedSvg } from '../../../assets/icons/home-red.svg';
import { ReactComponent as IconIfoodTembiciSvg } from '../../../assets/svgs/ifood-tembici.svg';
import { ReactComponent as IconIfoodTembiciGraySvg } from '../../../assets/svgs/ifood-tembici-gray.svg';
import { ReactComponent as IconHelpSvg } from '../../../assets/icons/icon-help.svg';
import { ReactComponent as IconBoltSvg } from '../../../assets/icons/icon-bolt.svg';
import { ReactComponent as IconPerfilSvg } from '../../../assets/icons/icon-perfil.svg';
import { ReactComponent as IconPerfilRedSvg } from '../../../assets/icons/icon-perfil-red.svg';
import { ReactComponent as IconQrCodeSvg } from '../../../assets/icons/icon-qr-code.svg';
import { ReactComponent as IconWarningSvg } from '../../../assets/icons/icon-warning.svg';
import { ReactComponent as IconWarningTriangleSvg } from '../../../assets/icons/icon-warning-triangle.svg';
import { ReactComponent as IconGiftSvg } from '../../../assets/icons/icon-gift.svg';
import { ReactComponent as IconGiftDarkerSvg } from '../../../assets/icons/icon-gift-darker.svg';
import { ReactComponent as IconInfoSvg } from '../../../assets/icons/icon-info.svg';
import { ReactComponent as IconCardSvg } from '../../../assets/icons/icon-card.svg';
import { ReactComponent as IconDolarSvg } from '../../../assets/icons/icon-dolar.svg';
import { ReactComponent as IconDolarCircleSvg } from '../../../assets/icons/icon-dolar-circle.svg';
import { ReactComponent as IconStopWatchSvg } from '../../../assets/icons/icon-stopwatch.svg';
import { ReactComponent as IconCashSvg } from '../../../assets/icons/icon-cash.svg';
import { ReactComponent as CheckSvg } from '../../../assets/svgs/check.svg';
import { ReactComponent as TipsSvg } from '../../../assets/icons/tips.svg';
import { ReactComponent as eloSvg } from '../../../assets/icons/elo.svg';
import { ReactComponent as mcSvg } from '../../../assets/icons/mc.svg';
import { ReactComponent as dinersSvg } from '../../../assets/icons/diners.svg';
import { ReactComponent as visaSvg } from '../../../assets/icons/visa.svg';
import { ReactComponent as starSvg } from '../../../assets/icons/icon-star.svg';
import { ReactComponent as hourglassSvg } from '../../../assets/icons/icon-hourglass.svg';
import { ReactComponent as disabledStarSvg } from '../../../assets/icons/icon-disabled-star.svg';
import { ReactComponent as patineteSvg } from '../../../assets/icons/patinete.svg';
import { ReactComponent as offButtonSvg } from '../../../assets/icons/off_button.svg';
import { ReactComponent as onButtonSvg } from '../../../assets/icons/on_button.svg';
import { ReactComponent as onOffDisabledSvg } from '../../../assets/icons/on_off_disabled.svg';
import { ReactComponent as NoMoreReward } from '../../../assets/svgs/no-more-reward.svg';
import { ReactComponent as GhostAnnoyed } from '../../../assets/icons/icon-ghost-annoyed.svg';
import { ReactComponent as Ghost } from '../../../assets/icons/icon-ghost.svg';
import { ReactComponent as IconTimerSvg } from '../../../assets/svgs/timer.svg';
import { ReactComponent as WaitingSvg } from '../../../assets/svgs/waiting.svg';
import { ReactComponent as BlockSvg } from '../../../assets/svgs/block.svg';
import { ReactComponent as DebitCardSvg } from '../../../assets/svgs/debit-card.svg';
import { ReactComponent as LockSvg } from '../../../assets/svgs/lock.svg';
import { ReactComponent as DuvidaSvg } from '../../../assets/svgs/duvida.svg';
import { ReactComponent as TrocaCartaoSvg } from '../../../assets/svgs/troca-cartao.svg';
import { ReactComponent as CalendarSvg } from '../../../assets/icons/calendar.svg';
import { ReactComponent as WalletMoneySvg } from '../../../assets/icons/wallet-money.svg';
import { ITheme } from '../../../interfaces/theme.interface';
import { Brand } from '../../../interfaces/creditCard.interface';
import { square } from '../../../themes/_mixins';

interface IIconProps extends ITheme {
  color?: string;
}

export type IconComponent = StyledComponent<React.FC<IIconProps>, Record<string, unknown>>;
type IconStyled = (icon: React.FC, filters?: { fill?: boolean; stroke?: boolean }) => IconComponent;

const iconColor = ({ theme, color }: IIconProps): string => color ?? theme.palette.text.primary;

const iconStyled: IconStyled = (Icon, { fill, stroke } = { fill: false, stroke: true }) => styled(
  () => <Icon />,
)`
  ${square(3)}
  & path {
    ${stroke &&
    css`
      stroke: ${iconColor};
    `}

    ${fill &&
    css`
      fill: ${iconColor};
    `}
  }
`;

export const IconMap = iconStyled(IconMapSvg);
export const IconEBike = iconStyled(IconEBikeSvg);
export const IconEBikeWhite = iconStyled(IconEBikeWhiteSvg);
export const IconFlag = iconStyled(IconFlagSvg);
export const IconHome = iconStyled(IconHomeSvg);
export const IconHomeRed = iconStyled(IconHomeRedSvg);
export const IconIfoodTembici = iconStyled(IconIfoodTembiciSvg);
export const IconIfoodTembiciGray = iconStyled(IconIfoodTembiciGraySvg);
export const IconHelp = iconStyled(IconHelpSvg);
export const IconBolt = iconStyled(IconBoltSvg, { fill: true });
export const IconPerfil = iconStyled(IconPerfilSvg);
export const IconPerfilRed = iconStyled(IconPerfilRedSvg);
export const IconQrCode = iconStyled(IconQrCodeSvg);
export const IconWarning = iconStyled(IconWarningSvg);
export const IconWarningTriangle = iconStyled(IconWarningTriangleSvg);
export const IconGift = iconStyled(IconGiftSvg);
export const IconGiftDarker = iconStyled(IconGiftDarkerSvg);
export const IconInfo = iconStyled(IconInfoSvg);
export const IconCard = iconStyled(IconCardSvg);
export const IconDolar = iconStyled(IconDolarSvg);
export const IconDolarCircle = iconStyled(IconDolarCircleSvg);
export const IconStopWatch = iconStyled(IconStopWatchSvg, { stroke: false });
export const IconCash = iconStyled(IconCashSvg);
export const IconCheck = iconStyled(CheckSvg);
export const IconTips = iconStyled(TipsSvg);
export const IconElo = iconStyled(eloSvg);
export const IconMc = iconStyled(mcSvg);
export const IconDiners = iconStyled(dinersSvg);
export const IconVisa = iconStyled(visaSvg);
export const IconStar = iconStyled(starSvg);
export const IconHourglass = iconStyled(hourglassSvg);
export const IconDisabledStar = iconStyled(disabledStarSvg);
export const IconPatinete = iconStyled(patineteSvg);
export const IconButtonOn = iconStyled(onButtonSvg);
export const IconButtonOff = iconStyled(offButtonSvg);
export const IconButtonOnOffDisabled = iconStyled(onOffDisabledSvg);
export const IconNoMoreReward = iconStyled(NoMoreReward);
export const IconGhostAnnoyed = iconStyled(GhostAnnoyed);
export const IconGhost = iconStyled(Ghost);
export const IconTimer = iconStyled(IconTimerSvg);
export const IconWaiting = iconStyled(WaitingSvg);
export const IconBlock = iconStyled(BlockSvg);
export const IconDebitCard = iconStyled(DebitCardSvg);
export const IconLockSvg = iconStyled(LockSvg);
export const IconDuvida = iconStyled(DuvidaSvg);
export const IconTrocaCartao = iconStyled(TrocaCartaoSvg);
export const IconCalendar = iconStyled(CalendarSvg);
export const IconWalletMoney = iconStyled(WalletMoneySvg);

export const brands: Record<Brand, IconComponent> = {
  elo: IconElo,
  mc: IconMc,
  diners: IconDiners,
  visa: IconVisa,
};
