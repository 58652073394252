import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useCallback } from 'react';
import { Button } from '../../atoms';
import * as S from './styles';
import { SwipeableKeys, CONTENT_DATA } from './content';
import { useSignupDocumentStore } from '../../../stores/useSignupDocumentStore';
import { AnalyticsService } from '../../../modules/temAnalytics';
import { backToIfoodPedal, messageRoute } from '../../../utils/route';
import { isFileInRightFormat, isFileSizeValid } from '../../../utils/image';
import { useToast } from '../../../hooks/useToast';
import { MessageTypes } from '../../../pages/InfoView/messages.const';
import { ROUTES } from '../../../constants/routes.const';
import { useGlobalLoadingStore } from '../../../stores/useGlobalLoadingStore';

export interface ISwipeableProp {
  openProp: boolean;
  onClose: () => void;
  customComponent?: JSX.Element;
  target?: SwipeableKeys;
  CONTENT?: {
    Title: string;
    List: {
      id: number;
      message: string;
    }[];
  };
  Route?: string;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  ButtonText?: string;
  showParagraphCircle?: boolean;
}

const Swipeable = ({
  openProp,
  onClose,
  CONTENT,
  Route,
  anchor,
  ButtonText,
  showParagraphCircle = false,
  customComponent,
  target,
}: ISwipeableProp): JSX.Element => {
  const navigate = useNavigate();
  const { setPhotoFrontDocument, setPhotoBackDocument, setPhotoProofResidenceDocument } =
    useSignupDocumentStore();

  const { setShowToast, setToastAnchor, setToastData, setBottom } = useToast();

  const { setIsGlobalLoading } = useGlobalLoadingStore();

  const {
    image,
    anchor: anchorContent,
    defaultMessage,
    title,
    defaultRoute,
    buttonText,
    buttonSecondaryText,
    isEnablePhoto,
    photoType,
    pageTitle,
    buttonEventName,
    buttonSecondaryEventName,
  } = CONTENT_DATA[target ?? 'THANKS_FOR_THE_FEEDBACK'];

  const titleFormatted = CONTENT?.Title || title;
  const anchorFormatted = anchor || anchorContent;
  const Icon = image;

  const photoTypeMap = {
    FRONT: {
      setDocument: setPhotoFrontDocument,
      route: messageRoute(MessageTypes.DOCUMENT_BACK),
    },
    BACK: {
      setDocument: setPhotoBackDocument,
      route: ROUTES.SIGNUP_DOCUMENT,
    },
    PROOF_OF_RESIDENCE: {
      setDocument: setPhotoProofResidenceDocument,
      route: ROUTES.SIGNUP_DOCUMENT,
    },
  };

  const onErrorMaxSize = () => {
    setToastData({
      message: 'O arquivo excede o tamanho máximo permitido (7MB).',
      variant: 'error',
    });
    setBottom({ bottom: '100px' });
    setToastAnchor({
      anchor: { horizontal: 'center', vertical: 'bottom' },
    });
    setShowToast();
  };

  const onErrorFileType = () => {
    setToastData({
      message: 'Documento deve ser PNG, JPG, JPEG ou PDF.',
      variant: 'error',
    });
    setBottom({ bottom: '100px' });
    setToastAnchor({
      anchor: { horizontal: 'center', vertical: 'bottom' },
    });
    setShowToast();
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { setDocument, route } = photoTypeMap[photoType!];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (!isFileSizeValid(file, 10)) {
        if (target === 'SEND_PHOTO_FRONT' || target === 'SEND_PHOTO_BACK') {
          AnalyticsService.instance.emitEvent({
            name:
              target === 'SEND_PHOTO_FRONT'
                ? 'id_validation_front_photo_fail'
                : 'id_validation_back_photo_fail',
            type: 'noninteraction',
            params: {
              page_title: pageTitle,
            },
          });
        } else if (target === 'PROOF_OF_RESIDENCE') {
          AnalyticsService.instance.emitEvent({
            name: 'address_validation_photo_fail',
            type: 'noninteraction',
            params: {
              page_title: pageTitle,
            },
          });
        }
        onErrorMaxSize();

        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        setDocument(base64String);
      };

      reader.readAsDataURL(file);
      setIsGlobalLoading(true);

      setInterval(() => {
        setIsGlobalLoading(false);
      }, 1000);

      if (target === 'SEND_PHOTO_FRONT' || target === 'SEND_PHOTO_BACK') {
        AnalyticsService.instance.emitEvent({
          name:
            target === 'SEND_PHOTO_FRONT'
              ? 'id_validation_front_photo_success'
              : 'id_validation_back_photo_success',
          type: 'noninteraction',
          params: {
            page_title: pageTitle,
          },
        });
      } else if (target === 'PROOF_OF_RESIDENCE') {
        AnalyticsService.instance.emitEvent({
          name: 'address_validation_photo_success',
          type: 'noninteraction',
          params: {
            page_title: pageTitle,
          },
        });
      }

      navigate(route);
    }
  };

  const handleDefaultButton = () => {
    // Sending analytics events if the targe supports it.
    if (pageTitle && buttonEventName) {
      AnalyticsService.instance.emitEvent({
        name: buttonEventName,
        type: 'interaction',
        params: {
          page_title: pageTitle,
        },
      });
    }

    if (target === 'CHECK_IN_SUCCESS') {
      navigate(backToIfoodPedal());
    } else if (defaultRoute) {
      window.location.href = defaultRoute;
    }

    if (isEnablePhoto) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.jpg, .jpeg, .png, .pdf';

      fileInput.onchange = event => {
        const file = (event.target as HTMLInputElement).files?.[0];

        if (file && !isFileSizeValid(file, 7)) {
          onErrorMaxSize();
          return;
        }

        if (file && !isFileInRightFormat(file.type)) {
          onErrorFileType();
          return;
        }

        handleImageChange(event as unknown as any);
      };

      fileInput.click();
    }
  };

  const handleSecondaryButton = useCallback(() => {
    if (buttonSecondaryEventName) {
      AnalyticsService.instance.emitEvent({
        name: buttonSecondaryEventName,
        type: 'interaction',
        params: {
          page_title: pageTitle,
        },
      });
    }
    onClose();
  }, [buttonSecondaryEventName, onClose, pageTitle]);

  return (
    <S.Swipeable
      anchor={anchorFormatted}
      open={openProp}
      onClose={onClose}
      onOpen={(): void => undefined}
    >
      <S.Container
        onClick={onClose}
        onKeyDown={onClose}
        role="presentation"
        data-testid="swipeable"
      >
        <Box display="flex" justifyContent="center" marginTop={1}>
          <S.PushIcon />
        </Box>
        <Box
          paddingTop={4}
          paddingBottom={3}
          display="flex"
          justifyContent="center"
          alignItems={showParagraphCircle ? 'flex-start' : 'center'}
          flexDirection="column"
          paddingLeft={showParagraphCircle ? 2 : 5}
          paddingRight={showParagraphCircle ? 2 : 5}
        >
          {!customComponent ? (
            <>
              {CONTENT?.Title && (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={3}
                    paddingLeft={1}
                  >
                    <div>
                      <S.ParagraphCircleIcon />
                    </div>
                    <Typography variant="h3">{titleFormatted}</Typography>
                  </Box>
                  <S.CustomDivider />
                </>
              )}

              {CONTENT && ButtonText && Route ? (
                <>
                  {CONTENT.List.map(item => (
                    <Box
                      key={item.id}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      marginTop={5}
                      gap={3}
                      paddingLeft={1}
                    >
                      {showParagraphCircle && (
                        <div>
                          <S.ParagraphCircleIcon />
                        </div>
                      )}

                      <Typography
                        textAlign={showParagraphCircle ? 'initial' : 'center'}
                        variant="p2"
                      >
                        {item.message}
                      </Typography>
                      <Box marginTop={4} width="100%">
                        <Button color="primary" fullWidth onClick={(): void => navigate(Route)}>
                          {ButtonText}
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={3}
                    paddingLeft={1}
                  >
                    {Icon && <Icon />}
                    {title && <Typography variant="h2">{title}</Typography>}
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    marginTop={2}
                    gap={3}
                    paddingLeft={1}
                  >
                    <Typography textAlign="center" variant="p4">
                      {defaultMessage}
                    </Typography>
                  </Box>

                  <Box marginTop={4} width="100%">
                    <Button color="primary" fullWidth onClick={handleDefaultButton}>
                      {buttonText}
                    </Button>

                    {buttonSecondaryText && (
                      <Button
                        color="primary"
                        variant="text"
                        fullWidth
                        onClick={handleSecondaryButton}
                      >
                        {buttonSecondaryText}
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </>
          ) : (
            customComponent
          )}
        </Box>
      </S.Container>
    </S.Swipeable>
  );
};

export default Swipeable;
