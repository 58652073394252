import IconMapSvg from '../../../../../assets/icons/icon-map.svg';
import IconClockSvg from '../../../../../assets/svgs/clock.svg';
import IconError2Svg from '../../../../../assets/svgs/error2.svg';
import IconEBikeSvg from '../../../../../assets/icons/icon-e-bike.svg';
import IconEBikeWhiteSvg from '../../../../../assets/icons/icon-e-bike-white.svg';
import IconHomeSvg from '../../../../../assets/icons/home.svg';
import IconHomeRedSvg from '../../../../../assets/icons/home-red.svg';
import IconFlagSvg from '../../../../../assets/icons/flag.svg';
import IconHelpSvg from '../../../../../assets/icons/icon-help.svg';
import IconPerfilSvg from '../../../../../assets/icons/icon-perfil.svg';
import IconPerfilDisabledSvg from '../../../../../assets/icons/icon-perfil-disabled.svg';
import IconPerfilRedSvg from '../../../../../assets/icons/icon-perfil-red.svg';
import IconHelpAttentionSvg from '../../../../../assets/icons/icon-help-attention.svg';
import IconHelpErrorSvg from '../../../../../assets/icons/icon-help-error.svg';
import IconHelpInformationSvg from '../../../../../assets/icons/icon-help-information.svg';
import IconHelpPositiveSvg from '../../../../../assets/icons/icon-help-positive.svg';
import IconTembiciGraySvg from '../../../../../assets/svgs/tembici-gray.svg';
import IconWarningTriangleSvg from '../../../../../assets/icons/icon-warning-triangle.svg';
import IconWarningTriangleAttentionSvg from '../../../../../assets/icons/icon-warning-triangle-attention.svg';
import IconWarningTriangleErrorSvg from '../../../../../assets/icons/icon-warning-triangle-error.svg';
import IconWarningTriangleInformationSvg from '../../../../../assets/icons/icon-warning-triangle-information.svg';
import IconWarningTrianglePositiveSvg from '../../../../../assets/icons/icon-warning-triangle-positive.svg';
import IconInvestigationSvg from '../../../../../assets/svgs/investigation.svg';
import IconCalendarAttentionSvg from '../../../../../assets/icons/calendarAttention.svg';
import IconCalendarInformationSvg from '../../../../../assets/icons/calendarInformation.svg';
import IconCalendarInformationGreySvg from '../../../../../assets/icons/calendarInformationGrey.svg';
import IconGpsPositiveSvg from '../../../../../assets/icons/gpsPositive.svg';
import IconArrowRightSvg from '../../../../../assets/icons/arrow-right.svg';
import IconTakePhotoSvg from '../../../../../assets/icons/take-photo.svg';
import IconGPSSvg from '../../../../../assets/icons/gps.svg';
import IconEloSvg from '../../../../../assets/icons/elo.svg';
import IconMCvg from '../../../../../assets/icons/mc.svg';
import IconDinersvg from '../../../../../assets/icons/diners.svg';
import IconVisasvg from '../../../../../assets/icons/visa.svg';
import IconCardsvg from '../../../../../assets/icons/icon-card.svg';
import IconLoadingLocationsvg from '../../../../../assets/svgs/loadingLocation.svg';
import IconTimersvg from '../../../../../assets/svgs/timer.svg';
import IconTimerWhitesvg from '../../../../../assets/icons/icon-timer-white.svg';
import IconLocationsvg from '../../../../../assets/svgs/location.svg';
import IconWalletsvg from '../../../../../assets/svgs/wallet.svg';
import IconCardErrorSvg from '../../../../../assets/svgs/card-error.svg';
import IconCheckOutlinedSvg from '../../../../../assets/icons/checkOutlined.svg';
import IconBikeReleasedSvg from '../../../../../assets/svgs/bike-released.svg';
import IconBikeSvg from '../../../../../assets/svgs/bike.svg';
import IconErrorOutlineSvg from '../../../../../assets/icons/error-outline.svg';

const IconMap = () => <img src={IconMapSvg} alt="map" />;
const IconHelp = () => <img src={IconHelpSvg} alt="help" />;
const IconHelpAttention = () => <img src={IconHelpAttentionSvg} alt="help-attention" />;
const IconHelpError = () => <img src={IconHelpErrorSvg} alt="help-error" />;
const IconHelpInformation = () => <img src={IconHelpInformationSvg} alt="help-information" />;
const IconHelpPositive = () => <img src={IconHelpPositiveSvg} alt="help-positive" />;
const IconHome = () => <img src={IconHomeSvg} alt="home" />;
const IconHomeRed = () => <img src={IconHomeRedSvg} alt="home-red" />;
const IconClock = () => <img src={IconClockSvg} alt="clock" />;
const IconError2 = () => <img src={IconError2Svg} alt="error2" />;
const IconEBike = () => <img src={IconEBikeSvg} alt="iconEbike" />;
const IconFlag = () => <img src={IconFlagSvg} alt="icon-flag" />;
const IconPerfil = () => <img src={IconPerfilSvg} alt="icon-perfil" />;
const IconPerfilRed = () => <img src={IconPerfilRedSvg} alt="icon-perfil-red" />;
const IconPerfilDisabled = () => <img src={IconPerfilDisabledSvg} alt="icon-perfil-disabled" />;
const IconEBikeWhite = () => <img src={IconEBikeWhiteSvg} alt="iconEbikeWhite" />;
const IconTembiciGray = () => <img src={IconTembiciGraySvg} alt="icon-ifood-tembici-gray" />;
const IconWarningTriangle = () => <img src={IconWarningTriangleSvg} alt="icon-warning-triangle" />;
const IconWarningTriangleAttention = () => (
  <img src={IconWarningTriangleAttentionSvg} alt="icon-warning-triangle-attention" />
);
const IconWarningTriangleError = () => (
  <img src={IconWarningTriangleErrorSvg} alt="icon-warning-triangle-error" />
);
const IconWarningTriangleInformation = () => (
  <img src={IconWarningTriangleInformationSvg} alt="icon-warning-triangle-information" />
);
const IconWarningTrianglePositive = () => (
  <img src={IconWarningTrianglePositiveSvg} alt="icon-warning-triangle-positive" />
);
const IconInvestigation = () => <img src={IconInvestigationSvg} alt="icon-investigation" />;
const IconCalendarAttention = () => (
  <img src={IconCalendarAttentionSvg} alt="icon-calendar-attention" />
);
const IconCalendarInformation = () => (
  <img src={IconCalendarInformationSvg} alt="icon-calendar-information" />
);
const IconCalendarInformationGrey = () => (
  <img src={IconCalendarInformationGreySvg} alt="icon-calendar-information-grey" />
);
const IconGpsPositive = () => <img src={IconGpsPositiveSvg} alt="icon-gps-positive" />;
const IconArrowRight = () => <img src={IconArrowRightSvg} alt="icon-arrow-right" />;
// eslint-disable-next-line jsx-a11y/img-redundant-alt
const IconTakePhoto = () => <img src={IconTakePhotoSvg} alt="take-photo" />;
const IconGPS = () => <img src={IconGPSSvg} alt="icon-gps" />;
const IconLoadingLocation = () => <img src={IconLoadingLocationsvg} alt="icon-loading-location" />;
const IconTimer = () => <img src={IconTimersvg} alt="icon-timer" />;
const IconTimerWhite = () => <img src={IconTimerWhitesvg} alt="icon-timer-white" />;
const IconLocation = () => <img src={IconLocationsvg} alt="icon-location" />;
const IconWallet = () => <img src={IconWalletsvg} alt="icon-wallet" />;

const IconElo = () => <img src={IconEloSvg} alt="icon-elo" />;
const IconMC = () => <img src={IconMCvg} alt="icon-mc" />;
const IconDiners = () => <img src={IconDinersvg} alt="icon-diners" />;
const IconVisa = () => <img src={IconVisasvg} alt="icon-visa" />;
const IconCard = () => <img src={IconCardsvg} alt="icon-card" />;

const IconCardError = () => <img src={IconCardErrorSvg} alt="icon-card-error" />;

const IconCheckOutlined = () => <img src={IconCheckOutlinedSvg} alt="icon-check-outlined" />;

const IconBikeReleased = () => <img src={IconBikeReleasedSvg} alt="icon-bike-released" />;

const IconBike = () => <img src={IconBikeSvg} alt="icon-bike-released" />;

const IconErrorOutline = () => <img src={IconErrorOutlineSvg} alt="icon-error-outline" />;

export {
  IconMap,
  IconHelp,
  IconClock,
  IconError2,
  IconEBike,
  IconFlag,
  IconEBikeWhite,
  IconHelpAttention,
  IconHelpError,
  IconHelpInformation,
  IconHelpPositive,
  IconTembiciGray,
  IconHome,
  IconHomeRed,
  IconPerfil,
  IconPerfilRed,
  IconPerfilDisabled,
  IconWarningTriangle,
  IconWarningTriangleAttention,
  IconWarningTriangleError,
  IconWarningTriangleInformation,
  IconWarningTrianglePositive,
  IconInvestigation,
  IconCalendarAttention,
  IconCalendarInformation,
  IconCalendarInformationGrey,
  IconGpsPositive,
  IconArrowRight,
  IconTakePhoto,
  IconGPS,
  IconCard,
  IconLoadingLocation,
  IconTimer,
  IconTimerWhite,
  IconLocation,
  IconWallet,
  IconCardError,
  IconCheckOutlined,
  IconBikeReleased,
  IconBike,
  IconErrorOutline,
};

export const brands = {
  elo: IconElo,
  mc: IconMC,
  diners: IconDiners,
  visa: IconVisa,
};
