import { useEffect, useRef, useState } from 'react';

const INITIAL_TIME = 59;
const INTERVAL = 1000;

interface TimerReturn {
  restartClock: () => void;
  time: number;
  setTime: (time: number) => void;
}

/**
 * creates a countdown timer
 * @param initialTime
 * @returns restart
 */
export const useTimer = (initialTime = INITIAL_TIME): TimerReturn => {
  const [startClock, setStartClock] = useState(false);
  const [time, setTime] = useState(initialTime);
  const clock = useRef<ReturnType<typeof setInterval>>();

  const restartClock = (): void => setStartClock(true);

  useEffect(() => {
    if (startClock) setTime(initialTime);
  }, [startClock, initialTime]);

  useEffect(() => {
    const startClockDown = (): void => {
      clearInterval(Number(clock.current));
      clock.current = setInterval(() => {
        setTime(remainingTime => remainingTime - 1);
      }, INTERVAL);
    };

    if (time === initialTime && startClock) {
      setStartClock(false);
      startClockDown();
    }
  }, [initialTime, time, startClock]);

  useEffect(() => {
    if (time === 0) {
      clearInterval(Number(clock.current));
    }
  }, [time]);

  useEffect(() => {
    return (): void => clearInterval(Number(clock.current));
  }, []);

  return { restartClock, time, setTime };
};
