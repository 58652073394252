import { ComponentType, FunctionComponent, lazy, LazyExoticComponent, Suspense } from 'react';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import { Spinner } from '../components/atoms';
import { LoggedRoute, PlanActiveRoute, ProxyPurchaseRoute } from '../components/guards';
import { ROUTES, BPV } from '../constants/routes.const';
import PurchaseSpecialPlan from '../pages/PurchaseSpecialPlan';
import Sentry, { SentryRoute } from '../services/sentry.service';
import { getItem, storeItem } from '../utils/localStorage';
import WarningListInfo from '../pages/WarningListInfo';
import MandatoryCourse from '../pages/MandatoryCourse';
import WarningList from '../pages/WarningList';

Sentry.init();

// https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
const lazyWithRetry = <T extends ComponentType<unknown>>(
  componentImport: () => Promise<{ default: T }>,
): LazyExoticComponent<T | FunctionComponent> =>
  lazy<T | FunctionComponent>(async () => {
    const pageHasAlreadyBeenForceRefreshed = getItem('page-has-been-force-refreshed') ?? false;

    try {
      const component = await componentImport();

      storeItem('page-has-been-force-refreshed', false);

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        storeItem('page-has-been-force-refreshed', true);

        window.location.reload();

        // React.lazy doesnt accept void | undefined to be returned
        const NullableComponent: FunctionComponent = () => null;
        return { default: NullableComponent };
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

type WithSuspense = (comp: LazyExoticComponent<FunctionComponent>) => () => JSX.Element;
const withSuspense: WithSuspense = Component => (): JSX.Element =>
  (
    <Suspense fallback={<Spinner />}>
      <Component />
    </Suspense>
  );

const Home = withSuspense(lazyWithRetry(() => import('../pages/Home')));
const AccountSettings = withSuspense(lazyWithRetry(() => import('../pages/AccountSettings')));
const InfoView = withSuspense(lazyWithRetry(() => import('../pages/InfoView')));
const UserPlan = withSuspense(lazyWithRetry(() => import('../pages/UserPlan')));
const ProgramDetails = withSuspense(lazyWithRetry(() => import('../pages/ProgramDetails')));
const Signup = withSuspense(lazyWithRetry(() => import('../pages/Signup')));
const Login = withSuspense(lazyWithRetry(() => import('../pages/Login')));
const PaymentMethod = withSuspense(lazyWithRetry(() => import('../pages/PaymentMethod')));
const PlanSelection = withSuspense(lazyWithRetry(() => import('../pages/PlanSelection')));
const PasswordTokenLogin = withSuspense(lazyWithRetry(() => import('../pages/PasswordTokenLogin')));
const PasswordTokenAccount = withSuspense(
  lazyWithRetry(() => import('../pages/PasswordTokenAccount')),
);
const NewPassword = withSuspense(lazyWithRetry(() => import('../pages/NewPassword')));
const AddCard = withSuspense(lazyWithRetry(() => import('../pages/AddCard')));
const Address = withSuspense(lazyWithRetry(() => import('../pages/Address')));
const PurchasePlan = withSuspense(lazyWithRetry(() => import('../pages/PurchasePlan')));
const MigratePlan = withSuspense(lazyWithRetry(() => import('../pages/MigratePlan')));
const CancelPlan = withSuspense(lazyWithRetry(() => import('../pages/CancelPlan')));
const Account = withSuspense(lazyWithRetry(() => import('../pages/Account')));
const UnlockBike = withSuspense(lazyWithRetry(() => import('../pages/UnlockBike')));
const BikeStations = withSuspense(lazyWithRetry(() => import('../pages/BikeStations')));
const Rewards = withSuspense(lazyWithRetry(() => import('../pages/Rewards')));
const Reward = withSuspense(lazyWithRetry(() => import('../pages/Reward')));
const BestPurchase = withSuspense(lazyWithRetry(() => import('../pages/BestPurchase')));
const MigrateDisclaimer = withSuspense(lazyWithRetry(() => import('../pages/MigrateDisclaimer')));
const HomeBpv = withSuspense(lazyWithRetry(() => import('../pages/HomeBpv')));
const InfoBikes = withSuspense(lazyWithRetry(() => import('../pages/InfoBikes')));
const SignupPersonalBpv = withSuspense(lazyWithRetry(() => import('../pages/SignupPersonalBpv')));
const AddressBpv = withSuspense(lazyWithRetry(() => import('../pages/AddressBpv')));
const SignupEmail = withSuspense(lazyWithRetry(() => import('../pages/SignupEmail')));
const OnBoarding = withSuspense(lazyWithRetry(() => import('../pages/OnBoarding')));
const SignupPhone = withSuspense(lazyWithRetry(() => import('../pages/SignupPhone')));
const SignupPhoneConfirm = withSuspense(lazyWithRetry(() => import('../pages/SignupPhoneConfirm')));
const SignupEmailResend = withSuspense(lazyWithRetry(() => import('../pages/SignupEmailResend')));
const SignupDocument = withSuspense(lazyWithRetry(() => import('../pages/SignupDocument')));
const SignUpDocumentFront = withSuspense(
  lazyWithRetry(() => import('../pages/SignUpDocumentFront')),
);
const SignUpDocumentBack = withSuspense(lazyWithRetry(() => import('../pages/SignUpDocumentBack')));

const SignUpCameraProofAddress = withSuspense(
  lazyWithRetry(() => import('../pages/SignUpCameraProofAddress')),
);

const SignUpDocumentConfirm = withSuspense(
  lazyWithRetry(() => import('../pages/SignUpDocumentConfirm')),
);

const SignUpCardScreen = withSuspense(lazyWithRetry(() => import('../pages/SignUpCardScreen')));

const PlansBpv = withSuspense(lazyWithRetry(() => import('../pages/PlansBpv')));

const SchedulePickupPlace = withSuspense(
  lazyWithRetry(() => import('../pages/SchedulePickupPlace')),
);

const ScheduleCalendar = withSuspense(lazyWithRetry(() => import('../pages/ScheduleCalendar')));

const ScheduleConfirmation = withSuspense(
  lazyWithRetry(() => import('../pages/ScheduleConfirmation')),
);

const ScheduleDetail = withSuspense(lazyWithRetry(() => import('../pages/ScheduleDetail')));

const CheckInBpv = withSuspense(lazyWithRetry(() => import('../pages/CheckInBpv')));

const CheckInBpvDone = withSuspense(lazyWithRetry(() => import('../pages/CheckInBpvDone')));

const BikeReleased = withSuspense(lazyWithRetry(() => import('../pages/BikeReleased')));

const CancelBpvReschedule = withSuspense(lazyWithRetry(() => import('../pages/BpvNotRenew')));

const Routes = (): JSX.Element => {
  return (
    <RoutesDom>
      <SentryRoute path={ROUTES.CADASTRO_CLIENTE} element={<Signup />} />
      <SentryRoute path={ROUTES.LOGIN} element={<Login />} />
      <SentryRoute path={ROUTES.MAIORES_DETALHES} element={<ProgramDetails />} />
      <SentryRoute path={`${ROUTES.INFO_VIEW}/:id`} element={<InfoView />} />
      <SentryRoute path={ROUTES.NOVA_SENHA} element={<NewPassword />} />
      <SentryRoute path={ROUTES.GERAR_TOKEN_LOGIN} element={<PasswordTokenLogin />} />
      <SentryRoute path={ROUTES.MELHOR_COMPRA} element={<BestPurchase />} />
      <SentryRoute path={ROUTES.COMPRA_NOVO_PLANO} element={<PurchasePlan />} />
      <SentryRoute path={ROUTES.MIGRAR_PLANO_SOBRE} element={<MigrateDisclaimer />} />

      <Route path={ROUTES.RESUMO_COMPRA} element={<ProxyPurchaseRoute />} />

      {[`${ROUTES.RESUMO_COMPRA}/:planId`, `${ROUTES.RESUMO_COMPRA}/:planId/:voucher`].map(
        (path, index) => (
          <Route key={index} path={path} element={<PurchaseSpecialPlan />} />
        ),
      )}

      <Route
        path={ROUTES.ENDERECO}
        element={
          <LoggedRoute>
            <Address />
          </LoggedRoute>
        }
      />
      {[ROUTES.HUB, ROUTES.ROOT].map((path, index) => (
        <Route
          key={index}
          path={path}
          element={
            <LoggedRoute>
              <Home />
            </LoggedRoute>
          }
        />
      ))}

      <Route
        path={ROUTES.SELECAO_PAGAMENTO}
        element={
          <LoggedRoute>
            <PaymentMethod />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.PLANOS}
        element={
          <LoggedRoute>
            <PlanSelection />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.GERAR_TOKEN_CONTA}
        element={
          <LoggedRoute>
            <PasswordTokenAccount />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.CONTA_CLIENTE}
        element={
          <LoggedRoute>
            <Account />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.PLANO_CLIENTE}
        element={
          <LoggedRoute>
            <UserPlan />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.CANCELAR_PLANO}
        element={
          <LoggedRoute>
            <CancelPlan />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.PONTOS_APOIO}
        element={
          <LoggedRoute>
            <BikeStations />
          </LoggedRoute>
        }
      />
      {[
        ROUTES.ATUALIZACAO_CARTAO,
        ROUTES.CADASTRO_CARTAO,
        ROUTES.TROCAR_CARTAO,
        BPV.ADD_CARD,
        BPV.UPDATE_CARD,
        BPV.UPDATE_CARD_CHECK_IN,
      ].map((path, index) => (
        <Route
          key={index}
          path={path}
          element={
            <LoggedRoute>
              <AddCard />
            </LoggedRoute>
          }
        />
      ))}
      <Route
        path={`${ROUTES.CONTA_RECOMPENSA}/:id`}
        element={
          <LoggedRoute>
            <Reward />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.CONTA_RECOMPENSA}
        element={
          <LoggedRoute>
            <Rewards />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.MIGRAR_PLANO}
        element={
          <LoggedRoute>
            <MigratePlan />
          </LoggedRoute>
        }
      />
      {[ROUTES.CONFIGURACAO_CONTA, ROUTES.ROOT].map((path, index) => (
        <Route
          key={index}
          path={path}
          element={
            <LoggedRoute>
              <AccountSettings />
            </LoggedRoute>
          }
        />
      ))}

      <Route
        path={ROUTES.RETIRADA_DEVOLUCAO}
        element={
          <PlanActiveRoute>
            <UnlockBike />
          </PlanActiveRoute>
        }
      />

      <Route
        path={ROUTES.WARNING_LIST_INFO}
        element={
          <LoggedRoute>
            <WarningListInfo />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.PEDAL_RESPONSA}
        element={
          <LoggedRoute>
            <MandatoryCourse />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.WARNING_LIST}
        element={
          <LoggedRoute>
            <WarningList />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.BPV_HOME}
        element={
          <LoggedRoute>
            <HomeBpv />
          </LoggedRoute>
        }
      />
      <Route
        path={ROUTES.ONBOARDING}
        element={
          <LoggedRoute>
            <OnBoarding />
          </LoggedRoute>
        }
      />

      <Route
        path={ROUTES.SIGNUP_PERSONAL}
        element={
          <LoggedRoute>
            <SignupPersonalBpv />
          </LoggedRoute>
        }
      />

      <Route
        path={ROUTES.INFO_BIKES}
        element={
          <LoggedRoute>
            <InfoBikes />
          </LoggedRoute>
        }
      />

      <Route
        path={ROUTES.SIGNUP_PHONE}
        element={
          <LoggedRoute>
            <SignupPhone />
          </LoggedRoute>
        }
      />

      <Route
        path={ROUTES.SIGNUP_PHONE_CONFIRM}
        element={
          <LoggedRoute>
            <SignupPhoneConfirm />
          </LoggedRoute>
        }
      />

      <Route
        path={ROUTES.SIGNUP_ADDRESS}
        element={
          <LoggedRoute>
            <AddressBpv />
          </LoggedRoute>
        }
      />

      <Route
        path={ROUTES.SIGNUP_EMAIL}
        element={
          <LoggedRoute>
            <SignupEmail />
          </LoggedRoute>
        }
      />

      <Route
        path={ROUTES.SIGNUP_EMAIL_RESEND}
        element={
          <LoggedRoute>
            <SignupEmailResend />
          </LoggedRoute>
        }
      />

      <Route path={ROUTES.SIGNUP_DOCUMENT} element={<SignupDocument />} />

      <Route path={ROUTES.SIGNUP_DOCUMENT_FRONT} element={<SignUpDocumentFront />} />

      <Route path={ROUTES.SIGNUP_DOCUMENT_BACK} element={<SignUpDocumentBack />} />

      <Route path={ROUTES.SIGNUP_CAMERA_PROOF_ADDRESS} element={<SignUpCameraProofAddress />} />

      <Route path={ROUTES.SIGNUP_CONFIRM_DOCUMENT} element={<SignUpDocumentConfirm />} />

      <Route path={ROUTES.SIGNUP_CARD_SCREEN} element={<SignUpCardScreen />} />

      <Route path={ROUTES.BPV_PLANS} element={<PlansBpv />} />

      <Route path={ROUTES.SCHEDULE_PICKUP_PLACE} element={<SchedulePickupPlace />} />

      <Route path={ROUTES.SCHEDULE_CALENDAR} element={<ScheduleCalendar />} />

      <Route path={ROUTES.SCHEDULE_CONFIRMATION} element={<ScheduleConfirmation />} />

      <Route path={ROUTES.SCHEDULE_DETAIL} element={<ScheduleDetail />} />

      <Route path={ROUTES.CHECK_IN} element={<CheckInBpv />} />

      <Route path={ROUTES.CHECK_IN_DONE} element={<CheckInBpvDone />} />

      <Route path={ROUTES.BIKE_RELEASED} element={<BikeReleased />} />

      <Route path={ROUTES.CANCEL_BPV_RENEW} element={<CancelBpvReschedule />} />
    </RoutesDom>
  );
};

export default Routes;
